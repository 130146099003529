
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './styles/app.css';
import Nav from './nav/Nav';
import About from './about/About';
import Skills from './skills/Skills';
import Projects from './projects/Projects';
import Contact from './contact/Contact';
import Background from './background/Background';
import PlayerStats from './playerStats/playerStats';

const App = () => {
  return (
    <Router>
      <Nav />
      <Background />
      <Routes>
        <Route path="/" element={<About/>} />
        <Route path="/skills" element={<Skills/>} />
        <Route path="/projects" element={<Projects/>} />
        <Route path="/contact" element={<Contact/>} />
      </Routes>
      <PlayerStats />
      <p style={{'position': 'fixed', 'top': 0, 'right': 15, 'white-space': 'nowrap', alignContent: 'center'}}>Free Stock Videos by <a href="http://www.videezy.com">Videezy</a></p>
    </Router>
  );
};

export default App;
