const skills = {
    1: [
      {
        title: "Cross-Functional Collaboration",
        level: 6,
      },
      {
        title: "Pricing Strategies",
        level: 4,
      },
      {
        title: "Competitve Analysis",
        level: 4,
      },
      {
        title: "Storytelling",
        level: 5,
      },
      {
        title: "Economics",
        level: 4,
      },
      {
        title: "Go-To-Market Strategies",
        level: 4,
      },
      {
        title: "KPIs / Metrics",
        level: 5,
      },
      {
        title: "User Research",
        level: 4,
      },
    ],
    2: [
      {
        title: "Artificial Intelligence",
        level: 5,
      },
      {
        title: "System Design",
        level: 5,
      },
      {
        title: "Python",
        level: 6,
      },
      {
        title: "Django",
        level: 4,
      },
      {
        title: "Git",
        level: 5,
      },
      {
        title: "SQL",
        level: 6,
      },
      {
        title: "Javascript",
        level: 5,
      },
      {
        title: "Data Analysis",
        level: 5,
      },
    ],
  };
  
  export default skills;