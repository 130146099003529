const subheadingsData = {
    1: [
        {
            title: 'About Me',
            content: (
                <>
                    <p>
                        
                        Welcome! I am Shantanu Phadke, a MBA in Artificial Intelligence candidate at the
                        Kellogg School of Management who enjoys working at the intersection of technology and business.
                        My experience at ServiceNow as a cross-functional product engineer highlights my proficiency
                        in enhancing technology products and user experiences. I am excited to delve into product management
                        or corporate strategy roles in the tech industry and continue expanding on my passion for innovation
                        and leadership!

                    </p>
                </>
            )
        }
    ],
    2: [
        {
            title: '2024 MBA in Artificial Intelligence Degree',
            content: (
                <>
                    <p>
                        The MBAi Program is an immersive joint-degree program that gives candidates
                        a rigorous business education integrated with a strong foundation in artificial
                        intelligence and machine learning. MBAi graduates receive a joint MBA degree conferred
                        by Kellogg School of Management and the McCormick School of Engineering and Applied Science
                        at Northwestern University.
                    </p>
                </>
            )
        },
        {
            title: '2019 Computer Science Masters Degree',
            content: (
                <>
                    <p>
                        Pursued a Cornell Tech, Cornell University's campus in NYC. Focus was on
                        Artificial Intelligence and Machine Learning through coursework such as
                        Natural Language Processing and Computer Vision, as well as general product
                        development through application based courses like Product Studio.
                    </p>
                </>
            )
        },
        {
            title: '2018 Computer Science & Business Administration Bachelors Degrees',
            content: (
                <>
                    <p>
                        Double majored in Computer Science & Business Administration at UC Berkeley.
                    </p>
                </>
            )
        }
    ],
    3: [
        {
            title: '2023 Staff Software Engineer (Software Asset Management @ ServiceNow)',
            content: (
                <>
                    <p>
                        Worked with cross-functional teams of product managers, customer support
                        engineers and sales people to drive sustained growth of the SAM product.
                        Translated key KPI of improving product usage into a workflow for setting up
                        key products like Microsoft Windows Server to go through license reconciliation.
                        Developed product roadmap for advanced licensing features including licensing
                        explanability & simulation.
                    </p>
                </>
            )
        },
        {
            title: '2021 Senior Software Engineer (Software Asset Management @ ServiceNow)',
            content: (
                <>
                    <p>
                        Led and coordinated various teams of engineers to expand SAM's licensing capabilities
                        for major publishers including Microsoft & IBM. Helped to reduce gaps in the product
                        and build out a competitive advantage.
                    </p>
                </>
            )
        },
        {
            title: '2019 Software Engineer (Software Asset Management @ ServiceNow)',
            content: (
                <>
                    <p>
                        Worked on developing features ranging from SaaS SSO management (to help customers
                        manage 1000s of SaaS apps through a single SSO provider connection), and domain
                        separating SAM (multiple tenants on a single ServiceNow instance). Developed / launched
                        first version of Hardware Asset Management mobile with inventory audit capabilities.
                    </p>
                </>
            ),
        },
    ],
};

export default subheadingsData;