import projectOne from "../assets/project-1.png";
import projectTwo from "../assets/project-2.png";
import projectThree from "../assets/project-3.png";

const projects = {
  1: {
    title: "Managize",
    image: projectOne,
    description: (
      <>
        <p>
          A platform to help users manage their goals, activities and habits.
        </p>
      </>
    ),
    github: "https://github.com",
    demo: "https://www.managize.io/",
  },
  2: {
    title: "ElectionTweetBoard",
    image: projectTwo,
    description: (
      <>
        <p>
          A dynamic dashboard that monitors the sentiments relating
          to Presidential candidates based on posts on X (formerly Twitter).
        </p>
      </>
    ),
    github: "https://github.com",
    demo: "https://www.electiontweetboard.com/",
  },
  3: {
    title: "Art",
    image: projectThree,
    description: (
      <>
        <p>
          During my free time I enjoy making visual art. Below is a link to my Instagram,
          where I post the artwork I create.
        </p>
      </>
    ),
    github: "https://github.com",
    demo: "https://www.instagram.com/shadyshantart/",
  },
};

export default projects;